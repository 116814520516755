'use client'

import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useEffect, useState } from 'react'
import { cx } from 'utils/cx'

import type { CountdownProps } from './Countdown.types'
import { DEFAULT_MAX_DAYS, msInS } from './constants'
import {
	isMsInDaysRange,
	padZeros,
	parseMillisecondsToDate,
} from './countdownHelpers'

import styles from './Countdown.module.scss'

const INITIAL_VALUE = -1

export const Countdown = ({
	date: ISODate,
	maxDays = DEFAULT_MAX_DAYS,
	className,
	numbersClassName,
}: CountdownProps) => {
	const { t } = useLabels()
	const dateTs = new Date(ISODate).getTime()
	const [msLeft, setMsLeft] = useState<number>(INITIAL_VALUE)

	useEffect(() => {
		const initialDiff = dateTs - Date.now()
		if (!Number.isNaN(initialDiff) && initialDiff > 0) {
			setMsLeft(initialDiff)
			const interval = setInterval(() => {
				const diff = dateTs - Date.now()
				if (Number.isNaN(diff) || diff <= 0) {
					clearInterval(interval)
					setMsLeft(0)
					return
				}
				setMsLeft(diff)
			}, msInS)

			return () => {
				clearInterval(interval)
			}
		}
		setMsLeft(0)
		return () => {}
	}, [dateTs])

	const isReadyDate = msLeft > 0 && isMsInDaysRange(dateTs, maxDays)
	const outOfRangeDate = !isReadyDate && msLeft > INITIAL_VALUE

	if (outOfRangeDate) {
		return null
	}

	const { days, hours, minutes, seconds } = parseMillisecondsToDate(msLeft || 0)

	const placeholderValues = {
		1: padZeros(days),
		2: padZeros(hours),
		3: padZeros(minutes),
		4: padZeros(seconds),
	}

	return (
		<div
			className={cx(className, !isReadyDate && styles.hidden)}
			role='timer'
			aria-hidden={isReadyDate ? undefined : 'true'}
		>
			<ClientLabel
				placeholderValues={placeholderValues}
				boldProps={{ className: cx(numbersClassName, styles.timePart) }}
			>
				{t('countdown.promo.text')}
			</ClientLabel>
		</div>
	)
}
