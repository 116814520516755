import { cx } from 'utils/cx'

import text from 'fukku/styles/texts.module.scss'
import styles from './BannerTitles.module.scss'

interface TitlesProps {
	readonly discount?: string
	readonly title?: string
	readonly subtitle?: string
	readonly subtitlesGroup?: {
		titleKey: string
		subtitleKey: string
	}[]
}

const showTitles = ({
	discount,
	title,
	subtitle,
	subtitlesGroup,
}: TitlesProps) => {
	const hasSubtitlesGroup = subtitlesGroup && subtitlesGroup?.length > 0
	return (
		Boolean(title) ||
		Boolean(subtitle) ||
		Boolean(discount) ||
		Boolean(hasSubtitlesGroup)
	)
}

export const BannerTitles = ({
	discount,
	title,
	subtitle,
	subtitlesGroup,
}: TitlesProps) => {
	const showBannerTitles = showTitles({
		title,
		subtitle,
		discount,
		subtitlesGroup,
	})

	return showBannerTitles ? (
		<div className={styles.titles}>
			{(title || discount) && (
				<h2 className={cx(styles.title, text.titleM)}>
					{title && <span>{title}</span>}
					{discount && <span>{discount}</span>}
				</h2>
			)}
			{subtitlesGroup?.map(({ titleKey, subtitleKey }) => (
				<div
					className={styles.subtitlesGroup}
					key={`${titleKey}-${subtitleKey}`}
				>
					<span className={text.bodySRegular}>{titleKey}</span>
					<span className={text.bodyS}>{subtitleKey}</span>
				</div>
			))}
			{subtitle && (
				<h3 className={cx(styles.subtitle, text.bodyS)}>{subtitle}</h3>
			)}
		</div>
	) : null
}
