import type { ParsedDate } from './Countdown.types'
import { NUMBER_OF_DIGITS, hInD, mInH, msInS, sInM } from './constants'

export function isMsInDaysRange(ms: number, days: number) {
	const nowMs = new Date().getTime()
	const nDaysBefore = ms - days * hInD * mInH * sInM * msInS

	return nowMs <= ms && nowMs >= nDaysBefore
}

export function parseMillisecondsToDate(ms: number): ParsedDate {
	const msInM = sInM * msInS
	const msInH = mInH * msInM
	const msInD = hInD * msInH

	const restingHours = ms % msInD
	const days = (ms - restingHours) / msInD

	const restingMinutes = restingHours % msInH
	const hours = (restingHours - restingMinutes) / msInH

	const restingSeconds = restingMinutes % msInM
	const minutes = (restingMinutes - restingSeconds) / msInM

	const seconds = Math.floor(restingSeconds / msInS)

	return {
		days,
		hours,
		minutes,
		seconds,
	}
}

export function padZeros(num: number, padding = NUMBER_OF_DIGITS): string {
	return String(num).padStart(padding, '0')
}
