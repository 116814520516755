import { useLayoutLayers } from 'hooks/useLayoutLayers/useLayoutLayers'
import type { ReactNode } from 'react'
import { createPortal } from 'react-dom'

export function OverDialogLayer({
	children,
}: {
	children: ReactNode
}) {
	const { topLayer } = useLayoutLayers()
	const childrenPortal = <>{children}</>
	return createPortal(childrenPortal, topLayer ?? document.body)
}
