export enum PageType {
	Backoffice = 'backoffice',
	Checkout = 'checkout',
	ConfirmationPage = 'purchase',
	DiscoverCategory = 'discover_category',
	DiscoverCategoryBrand = 'discover_category_brand',
	DiscoverHome = 'discover_home',
	DiscoverPost = 'discover_post',
	Editorials = 'editorials',
	Error500 = 'error_500',
	Favorites = 'favorites',
	GiftVoucher = 'gift_voucher',
	Help = 'help',
	Home = 'home',
	PLPTier2 = 'plp_tier2',
	Landings = 'landings',
	Login = 'login',
	MangoLikesYou = 'mango_likes_you',
	MyDetails = 'my_details',
	MyMangoLikesYou = 'my_mango_likes_you',
	MyAccount = 'my_account',
	MySubscriptions = 'my_subscriptions',
	MyPurchases = 'my_purchases',
	NewsletterDoubleOptinConfirmation = 'newsletter-double-optin-confirmation',
	NotFound = 'not_found',
	PDP = 'pdp',
	PLP = 'plp',
	PrivacyPolicy = 'privacy_policy',
	RecoveryPassword = 'recovery_password',
	Refunds = 'refunds',
	Registry = 'registry',
	ResetPassword = 'reset_password',
	Responsibility = 'responsibility',
	Search = 'search',
	ShoppingCart = 'bag',
	SizeGuide = 'size_guide',
	Sustainability = 'sustainability',
	FashionAssistant = 'fashion_assistant',
	Unknown = 'unknown',
	MyReturns = 'my_returns',
	MyAddresses = 'my_addresses',
	ConformityDeclaration = 'conformity_declaration',
	AccountBalance = 'account_balance',
	StoresLocator = 'stores_locator',
	Sitemap = 'sitemap',
}
