/**
 * Height of the arrow
 */
const ARROW_HYPOTENUSE = 11.3137
const ARROW_ROTATION = 45
const ARROW_ANCHOR = 'top left'
const HALF = 0.5

interface TooltipLimits {
	left: number
	right: number
}

interface TooltipTriggerProps {
	top: number
	left: number
	bottom: number
	right: number
	width: number
	height: number
}

interface TooltipProps {
	width: number
	height: number
}

interface TooltipStyleProps {
	offset: number
	limits: TooltipLimits
	trigger: TooltipTriggerProps
	tooltip: TooltipProps
	reverse: boolean
}

export interface TooltipArrowStyle {
	left?: string
	top?: string
	borderTopWidth?: string
	borderLeftWidth?: string
	borderBottomWidth?: string
	borderRightWidth?: string
	transform?: string
	transformOrigin?: string
}

export interface TooltipContentStyle {
	left?: string
	top?: string
	width?: string
	maxWidth?: string
	transform?: string
	reverse?: boolean
}

export interface TooltipStyleResult {
	arrowStyles: TooltipArrowStyle
	tooltipStyles: TooltipContentStyle
}

const SPACE_BETWEEN_TOOLTIP_ARROW = 20
const MIN_LEFT_TOOLTIP_MARGIN = 0

const getTranslateX = ({
	leftTooltip,
	limits,
	tooltip,
}: {
	leftTooltip: number
	limits: TooltipLimits
	tooltip: TooltipProps
}) => {
	let translateX: number

	if (leftTooltip < limits.left) {
		translateX = Math.abs(leftTooltip - limits.left)
	} else if (leftTooltip + tooltip.width > limits.right) {
		translateX = limits.right - leftTooltip - tooltip.width
	} else {
		translateX = 0
	}

	return translateX
}

export const getBottomStyles = (
	tooltipStyleProps: TooltipStyleProps
): TooltipStyleResult => {
	const { trigger, offset, tooltip, limits } = tooltipStyleProps
	const topArrow = trigger.bottom + offset
	const leftArrow = trigger.left + trigger.width * HALF
	const leftTooltip = leftArrow - tooltip.width * HALF
	const translateX = getTranslateX({ leftTooltip, limits, tooltip })

	const arrowStyles: TooltipArrowStyle = {
		top: `${topArrow}px`,
		left: `${leftArrow}px`,
		borderTopWidth: '1px',
		borderLeftWidth: '1px',
		transform: `rotate(${ARROW_ROTATION}deg)`,
		transformOrigin: ARROW_ANCHOR,
	}

	const leftReverseCalculation = leftArrow - SPACE_BETWEEN_TOOLTIP_ARROW

	const tooltipStyles: TooltipContentStyle = {
		top: `${Math.floor(topArrow + ARROW_HYPOTENUSE * HALF)}px`,
		left: tooltipStyleProps.reverse
			? `${Math.max(leftReverseCalculation, MIN_LEFT_TOOLTIP_MARGIN)}px`
			: `${leftTooltip}px`,
		transform: tooltipStyleProps.reverse
			? `translateX(${0}px)`
			: `translateX(${translateX}px)`,
		maxWidth: tooltipStyleProps.reverse
			? 'none'
			: `${limits.right - limits.left}px`,
		width: tooltipStyleProps.reverse ? 'auto' : `${tooltip.width}px`,
	}

	return { arrowStyles, tooltipStyles }
}

export const getTopStyles = (
	tooltipStyleProps: TooltipStyleProps
): TooltipStyleResult => {
	const { trigger, offset, tooltip, limits } = tooltipStyleProps
	const topArrow = trigger.top - offset - ARROW_HYPOTENUSE
	const leftArrow = trigger.left + trigger.width * HALF
	const leftTooltip = leftArrow - tooltip.width * HALF
	const translateX = getTranslateX({ leftTooltip, limits, tooltip })

	const arrowStyles: TooltipArrowStyle = {
		top: `${topArrow}px`,
		left: `${leftArrow}px`,
		borderBottomWidth: '1px',
		borderRightWidth: '1px',
		transform: `rotate(${ARROW_ROTATION}deg)`,
		transformOrigin: ARROW_ANCHOR,
	}
	const tooltipStyles: TooltipContentStyle = {
		top: `${Math.ceil(topArrow - tooltip.height + ARROW_HYPOTENUSE * HALF)}px`,
		left: `${leftTooltip}px`,
		transform: `translateX(${translateX}px)`,
		maxWidth: `${limits.right - limits.left}px`,
		width: `${tooltip.width}px`,
	}

	return { arrowStyles, tooltipStyles }
}

export const getRightStyles = (
	tooltipStyleProps: TooltipStyleProps
): TooltipStyleResult => {
	const { trigger, offset, tooltip } = tooltipStyleProps
	const topArrow = trigger.top + trigger.height * HALF - ARROW_HYPOTENUSE * HALF
	const leftArrow = trigger.right + offset + ARROW_HYPOTENUSE * HALF

	const arrowStyles: TooltipArrowStyle = {
		top: `${topArrow}px`,
		left: `${leftArrow}px`,
		borderBottomWidth: '1px',
		borderLeftWidth: '1px',
		transform: `rotate(${ARROW_ROTATION}deg)`,
		transformOrigin: ARROW_ANCHOR,
	}
	const tooltipStyles: TooltipContentStyle = {
		top: `${topArrow - tooltip.height * HALF + ARROW_HYPOTENUSE * HALF}px`,
		left: `${leftArrow}px`,
		width: `${tooltip.width}px`,
	}
	return { arrowStyles, tooltipStyles }
}

export const getLeftStyles = (
	tooltipStyleProps: TooltipStyleProps
): TooltipStyleResult => {
	const { trigger, offset, tooltip } = tooltipStyleProps
	const topArrow = trigger.top + trigger.height * HALF - ARROW_HYPOTENUSE * HALF
	const leftArrow = trigger.left - offset - ARROW_HYPOTENUSE * HALF

	const arrowStyles: TooltipArrowStyle = {
		top: `${topArrow}px`,
		left: `${leftArrow}px`,
		borderTopWidth: '1px',
		borderRightWidth: '1px',
		transform: `rotate(${ARROW_ROTATION}deg)`,
		transformOrigin: ARROW_ANCHOR,
	}
	const tooltipStyles: TooltipContentStyle = {
		top: `${topArrow - tooltip.height * HALF + ARROW_HYPOTENUSE * HALF}px`,
		left: `${leftArrow - tooltip.width}px`,
		width: `${tooltip.width}px`,
	}
	return { arrowStyles, tooltipStyles }
}

interface TriggerPositions {
	top: number
	left: number
	bottom: number
	right: number
}

export const getTriggerPositions = (triggerRect: DOMRect): TriggerPositions => {
	const bottom = triggerRect.bottom + document.documentElement.scrollTop
	const top = triggerRect.top + document.documentElement.scrollTop
	const { left, right } = triggerRect

	return {
		top,
		left,
		bottom,
		right,
	}
}
