import type { RefObject } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import type { ClientTextLinkProps } from '../../../TextLink/client'

import styles from './CommBannerLinkWrapper.module.scss'

interface CommBannerLinkWrapperProps {
	readonly tabIndex: -1 | undefined
	readonly commRef: RefObject<HTMLDivElement>
	readonly colorStyle: Record<string, unknown>
	readonly wrapperClassName: string
	readonly children: React.ReactNode
	readonly firstLink?: ClientTextLinkProps
}
export function CommBannerLinkWrapper({
	tabIndex,
	commRef,
	colorStyle,
	wrapperClassName,
	children,
	firstLink,
}: CommBannerLinkWrapperProps) {
	const hasOnlyOneLink = firstLink?.href && firstLink?.children
	const isHashLink = firstLink?.href?.startsWith('#')

	if (hasOnlyOneLink && isHashLink) {
		return (
			<div ref={commRef}>
				<a
					href={firstLink.href}
					onClick={firstLink.onClick}
					className={cx(wrapperClassName, styles.noUnderline)}
					style={colorStyle}
				>
					{children}
				</a>
			</div>
		)
	}

	if (hasOnlyOneLink) {
		return (
			<div ref={commRef}>
				<ClientLink
					{...firstLink}
					linkProps={{
						...firstLink.linkProps,
						title: firstLink.children as string,
						className: cx(wrapperClassName, styles.noUnderline),
						style: colorStyle,
						tabIndex,
					}}
					href={firstLink?.href}
				>
					{children}
				</ClientLink>
			</div>
		)
	}

	return (
		<div ref={commRef} className={wrapperClassName} style={colorStyle}>
			{children}
		</div>
	)
}
